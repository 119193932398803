@import "_common.scss";
.service {
  // Spacing between sections that are not the office details first section.
  > section:not(:first-of-type) + section {
    margin-top: $section-space;
  }

  .related-section {
    .card.vertical {
      margin-bottom: 80px;

      .text {
        padding: 0;

        .subtitle h4 {
          margin: 0 0 20px;
        }
      }

      .title {
        text-transform: uppercase;
        font-size: 1.1rem;
        display: block;
      }
    }
  }

  .partners-cards,
  .partners-gallery {
    padding: 0 $column;
    color: $grey-darkest;

    .gallery {
      padding-top: calc(#{$column} / 4);

      .tns-outer {
        padding: 0 calc(#{$column} / 2) !important;
      }

      .controls {
        // JWTSOLWTCOM-571
        max-width: none;
      }

      #tns1 {
        display: flex;
        justify-content: center;
      }

      .slider.disable-desktop,
      .slider.disable-tablet {
        padding-bottom: calc(#{$column} / 4);

        justify-content: space-evenly;
        img {
          max-width: 100%;
          width: auto;
          height: auto;
        }
      }
    }

    p {
      font-size: 1.125rem;
      margin: 2.125rem auto;
    }

    .buttons {
      text-align: center;

      .partners-btn {
        margin: 0 auto;
        display: inline-block;
      }
    }
  }

  .partners-heading {
    text-align: center;
    text-transform: capitalize;
    font-size: 2.25rem;
  }

  .partners-gallery {
    margin-bottom: calc(#{$column} / 2);

    .partners-btn {
      margin: 0 auto;
    }

    .gallery {
      margin-bottom: 2rem;

      #tns1 {
        margin: 0 auto;
      }

      .slide img {
        max-height: 150px;
      }

      .bullets {
        display: none;
      }

      .controls {
        left: 0;
        width: 100%;
        margin: 0 auto;

        .arrow {
          margin: 0;

          &.next {
            padding-right: 10px;
          }

          &.prev {
            padding-left: 10px;
          }

          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 60%;
            display: block;
            width: 25px;
            height: 25px;
            background: transparent;
          }

          &:before {
            border-bottom: 3px solid $black;
            transform: translate(60%, -55%) rotate(-65deg);
          }

          &:after {
            border-right: 3px solid $black;
            transform: translate(50%, -100%) rotate(-25deg);
          }
        }
      }
    }
  }
}

@include tablet() {
  .service {
    > section:last-child {
      margin-bottom: $section-space-mobile;
    }

    .capabilities {
      .accordion-section {
        .accordion-container {
          padding: 0 $column;
        }
      }
    }
  }
}

@include mobile() {
  .service {
    > section:not(:first-of-type) + section {
      margin-top: $section-space-mobile;
    }

    .related-section {
      padding: 0;

      .card.vertical {
        margin-bottom: 0;

        .text-holder {
          .title {
            letter-spacing: 3px;
            margin-bottom: 1rem;
          }
        }
      }

      .section-title {
        padding: 0 $column 1.5rem $column;
      }
    }

    .partners-gallery {
      margin-bottom: 3.75rem;

      .gallery {
        padding-top: 0;
      }

      .tns-slider {
        align-items: center;
        display: flex;
      }
    }
  }
}
